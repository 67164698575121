<template>
  <div class="addhelp-document">
    <div class="text">标题:</div>
    <div class="my-title">
      <el-input type="input" v-model.trim="title" placeholder="标题默认加粗"></el-input>
    </div>
    <div class="text">文章内容:</div>
    <div class="containe">
      <el-input type="textarea" resize="none" placeholder="在此输入" v-model.trim="content"></el-input>
    </div>
    <div class="text">封面和摘要:</div>
    <div class="up-load">
      <upLoad @setImageUrl="setImageUrl" :coverImage="coverImage" />
      <el-input type="textarea" v-model.trim="abstract" @input="descInput($event)" maxlength="120" resize="none" placeholder="选填,摘要回在文章外显露,帮助读者快速了解内容。"></el-input>
      <div class="number">{{ number }}/120</div>
    </div>
    <div class="text">对谁可见:
      <el-radio v-model="requester" label="requester" @click.native.prevent="cancel('requester')">发布者</el-radio>
      <el-radio v-model="worker" label="worker" @click.native.prevent="cancel('worker')">标注员</el-radio>
      <el-radio v-model="app" label="app" @click.native.prevent="cancel('app')">app</el-radio>
    </div>
    <div class="fn">
      <el-button class="normal same" @click="save">保存</el-button>
      <el-button class="normal same" @click="visible = true">预览</el-button>
      <el-button class="blue same" @click="publish" v-if="publishFlag">发布</el-button>
    </div>
    <preview-equipment
      :title="title"
      :content="content"
      :visible.sync="visible"
    ></preview-equipment>
  </div>
</template>

<script>
import { help, helpPublish, getOneHelp } from '@/api/tips'
import upLoad from '../../components/fanhan-upload.vue'
import PreviewEquipment from '@/components/PreviewEquipment'

export default {
  name: 'addhelp-document',
  components: {
    upLoad,
    PreviewEquipment
  },
  data () {
    return {
      visible: false,
      title: '',
      id: '',
      content: '', // 文章内容
      abstract: '', // 摘要
      number: 0,
      coverImage: '',
      requester: '',
      worker: '',
      app: '',
      publishFlag: true
    }
  },
  mounted () {
    this.id = this.$route.query.id
    if (this.$route.query.id) {
      this.init()
    }
  },
  methods: {
    async init () {
      const res = await getOneHelp(Number(this.id))
      if (res.code === 0) {
        this.content = res.data.content
        this.abstract = res.data.abstract
        this.title = res.data.title
        this.coverImage = res.data.coverImage
        if (res.data.publishedAt) {
          this.publishFlag = false
        }
        if (res.data.endpoints.includes('requester')) {
          this.requester = 'requester'
        }
        if (res.data.endpoints.includes('worker')) {
          this.worker = 'worker'
        }
        if (res.data.endpoints.includes('app')) {
          this.app = 'app'
        }
      }
    },
    descInput (e) {
      this.number = e.length
    },
    save () {
      let endpoints = [this.requester, this.worker, this.app]
      endpoints = endpoints.filter(it => { return it !== '' })
      if (this.content !== '' || this.title !== '' || this.abstract !== '' || this.coverImage !== '' || endpoints.length !== 0) {
        const data = {
          content: this.content,
          abstract: this.abstract,
          coverImage: this.coverImage,
          title: this.title,
          endpoints
        }
        if (this.id) {
          data.id = +this.id
        }
        help(data).then(res => {
          if (res.code === 0) {
            this.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success',
              duration: '1000'
            })
            // 产品不要返回到列表页有id参数怎么判断传还是不传
            setTimeout(() => { this.$router.go(-1) }, 1000)
          }
        })
      } else {
        this.$notify({
          title: '警告',
          message: '内容为空,无法保存',
          type: 'error'
        })
      }
    },
    publish () {
      // 发布
      let endpoints = [this.requester, this.worker, this.app]
      endpoints = endpoints.filter(it => { return it !== '' })
      if (this.content === '' || this.title === '' || this.coverImage === '' || endpoints.length === 0) {
        this.$notify({
          title: '警告',
          message: '请填写完整内容',
          type: 'error',
          duration: '1000'
        })
      } else {
        const data = {
          content: this.content,
          abstract: this.abstract,
          coverImage: this.coverImage,
          title: this.title,
          endpoints
        }
        if (this.id) {
          data.id = +this.id
        }
        // 保存再发布
        help(data).then(res => {
          if (res.code === 0) {
            helpPublish({ id: res.data.id }).then(resp => {
              if (resp.code === 0) {
                this.$notify({
                  title: '成功',
                  message: '发布成功',
                  type: 'success',
                  duration: '1000'
                })
                setTimeout(() => { this.$router.go(-1) }, 1000)
              } else {
                this.$notify({
                  title: '警告',
                  message: '发布失败',
                  type: 'error',
                  duration: '1000'
                })
              }
            })
          }
        })
      }
    },
    setImageUrl (url) {
      this.coverImage = url
    },
    cancel (type) {
      if (type === 'requester') {
        if (type === this.requester) {
          this.requester = ''
        } else {
          this.requester = type
        }
      }
      if (type === 'worker') {
        if (type === this.worker) {
          this.worker = ''
        } else {
          this.worker = type
        }
      }
      if (type === 'app') {
        if (type === this.app) {
          this.app = ''
        } else {
          this.app = type
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.addhelp-document{
  max-width: 1000px;
  min-width: 500px;
  .text{
    font-size: 14px;
    color: #333333;
    font-weight: bold;
    margin: 12px 0px;
    /deep/.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner{
      box-shadow: none;
    }
    .el-radio{
      margin-left: 60px;
      /deep/.el-radio__input.is-checked .el-radio__inner{
        border-color: #3775F6;
        background: #3775F6;
      }
      /deep/.el-radio__input.is-checked+.el-radio__label{
        color: #3775F6;
      }
      /deep/.el-radio__inner:hover{
        border-color: #DCDFE6;
        box-shadow: none;
      }
    }
  }
  .my-title{
    /deep/.el-input__inner{
      height: 100%;
      height: 40px;
      border-radius: 2px;
    }
    /deep/.el-input__inner:focus{
      border-color: #3775F6;
    }
  }
  .containe{
    height: 350px;
    width: 100%;
    margin-bottom: 20px;
    .el-textarea{
      height: 100%;
    }
    /deep/.el-textarea__inner{
      height: 100%;
      border-radius: 2px;
    }
    /deep/.el-textarea__inner:focus{
      border-color: #3775F6;
    }
  }
  .up-load{
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
    .number{
      font-size: 12px;
      color: #999999;
      position: absolute;
      right: 8px;
      bottom: -2px;
    }
    .el-textarea{
      margin-left: 12px;
      height: 100%;
    }
    /deep/.el-textarea__inner{
      height: 100%;
      border-radius: 2px;
    }
    /deep/.el-textarea__inner:focus{
      border-color: #3775F6;
    }
  }
  .fn{
    display: flex;
    margin-top: 40px;
    /deep/.same{
      width: 76px;
      height: 32px;
      line-height: 8px;
      border-radius: 2px;
      outline: 0;
    }
    /deep/.normal{
      color: #3775F6;
      border-color: #3775F6;
    }
    /deep/.blue{
      color: #fff;
      background: #3775F6;
      border-color: #3775F6;
    }
  }
}
</style>
